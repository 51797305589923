import { ProfileInfoContainer, tierHandler } from "../common/ProfileInfo";
import Flex from "../ui/Flex";
import phone from "src/assets/images/profile/phone.svg";
import mail from "src/assets/images/profile/mail.svg";
import chat from "src/assets/images/profile/chat.svg";
import close from "src/assets/images/common/close.svg";
import { Button, ButtonClass, ConfirmationModal, Loading } from "../ui";
import { useState, useEffect } from "react";
import { auth } from "src/store/Auth";
import SendMailModal from "../users/SendMailModal";
import SendNotificationModal from "../users/SendNotificationModal";
import ChangeWithdrawalBankAccount from "../users/ChangeWithdrawalBankModel";
import { ArtisansServices } from "src/service/ArtisansServices";
import { toast } from "react-toastify";
import { useLoading } from "src/hooks";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { humanReadableDate } from "src/utils";
import { DeleteOutline } from "@mui/icons-material";
import userServices from "src/service/userServices";
import userIcon from "../../assets/images/profile/user-placeholder.png";

const ArtisansProfileCard = ({
	artisanDetails,
	getArtisan,
}: {
	artisanDetails: ArtisanProfileDetails;
	getArtisan: (id: string) => void;
}) => {
	const [openSendNotificationModal, setOpenSendNotificationModal] =
		useState(false);
	const handleOpenNotificationModal = () => setOpenSendNotificationModal(true);
	const handleCloseNotificationModal = () =>
		setOpenSendNotificationModal(false);
	const {
		loading: isDeletingPicture,
		startLoading: startProfilePicDeletion,
		stopLoading: endProfifilePicDeletion,
	} = useLoading();
	//Check if Logged Admin is an Occupational Manager
	const { authUser } = auth.use();

	const [isOccupManager, setIsOccupManager] = useState(false);

	useEffect(() => {
		//Set State to true is Occupational Manager is logged in
		if (authUser?.role?.includes("occupationalManager")) {
			setIsOccupManager(true);
		}
	}, [authUser]);
	const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
		useState(false);
	const [openSendMailModal, setOpenSendMailModal] = useState(false);
	const [userEmail, setUserEmail] = useState("");
	const [userName, setUserName] = useState("");
	const { loading, startLoading, stopLoading } = useLoading();
	const [suspendArtisan, setSuspendArtisan] = useState(true);
	const handleOpenMailModal = (email: string, name: string) => {
		setUserEmail(email);
		setUserName(name);
		setOpenSendMailModal(true);
	};
	const handleCloseMailModal = () => setOpenSendMailModal(false);

	const [openChangeBankModal, setOpenChangeBankModal] = useState(false);
	const toggleChangeBankModal = () => {
		setOpenChangeBankModal(() => !openChangeBankModal);
	};

	const {
		loading: undeleteUser,
		startLoading: startUndeletingUsers,
		stopLoading: stopUndeletingUsers,
	} = useLoading(false);

	const updateArtisanDeleted = () => {
		startUndeletingUsers();
		ArtisansServices.updateArtisan(artisanDetails._id || "", {
			deleted: "No",
		})
			.then((res) => {
				getArtisan(artisanDetails._id || "");
				toast.success(res?.data?.message);
			})
			.catch((err) => {
				// console.log(err.response);
				toast.error(err?.response?.data?.error?.message);
			})
			.finally(() => stopUndeletingUsers());
	};

	const suspendArtisans = () => {
		startLoading();
		if (!artisanDetails.suspended) {
			ArtisansServices.suspendArtisan(artisanDetails._id || "", {
				reason: "Low rating and complaint raised by a customer",
			})
				.then((res) => {
					// console.log(res?.data);
					toast.success(res?.data?.message);
					getArtisan(artisanDetails._id || "");
				})
				.catch((err) => {
					// console.log(err.response);
					toast.error(err?.response?.data?.error?.message);
				})
				.finally(() => stopLoading());
		} else {
			ArtisansServices.unSuspendArtisan(artisanDetails._id || "", {
				reason: "Low rating and complaint raised by a customer",
			})
				.then((res) => {
					// console.log(res?.data);
					toast.success(res?.data?.message);
					getArtisan(artisanDetails._id || "");
				})
				.catch((err) => {
					console.log(err.response);
				})
				.finally(() => stopLoading());
		}
	};

	const deleteProfilePicture = () => {
		startProfilePicDeletion();
		ArtisansServices.deleteProfilePic(artisanDetails?._id || "")
			.then((res) => {
				toast.success(res?.data?.message);
				window.location.reload();
			})
			.catch((err) => {
				toast.error(err?.response?.data?.error?.message);
			})
			.finally(() => endProfifilePicDeletion());
	};

	return (
		<ProfileInfoContainer>
			<div className='heading'>
				<Flex justify='space-between' align='center' wrap='wrap'>
					<h1 className='text-2xl font-semibold'>Profile Information</h1>
					<div>
						<Flex>
							<Button
								onClick={() => {
									// setSuspendArtisan((prevState) => !prevState);
									suspendArtisans();
								}}
								classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
								style={{
									width: "max-content",
									backgroundColor: artisanDetails.suspended
										? "#00CCCD"
										: "#EB5656",
								}}
								className={`${isOccupManager ? "!hidden" : ""}`}
								disabled={loading}
							>
								{loading ? (
									<ClipLoader size={20} />
								) : (
									<>
										{!artisanDetails.suspended && <img src={close} alt='x' />}
										<span>
											{artisanDetails.suspended
												? "Activate Account"
												: "Deactivate Account"}
										</span>
									</>
								)}
							</Button>
							{artisanDetails.deleted && (
								<Button
									onClick={() => {
										updateArtisanDeleted();
									}}
									classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
									style={{
										backgroundColor: "#00CCCD",
										width: "max-content",
									}}
									disabled={undeleteUser}
								>
									{undeleteUser ? (
										<ClipLoader size={20} />
									) : (
										<>
											<span>Undelete Account</span>
										</>
									)}
								</Button>
							)}
						</Flex>
					</div>
				</Flex>
			</div>
			<SendMailModal
				open={openSendMailModal}
				userEmail={userEmail}
				user={userName}
				handleClose={handleCloseMailModal}
			/>
			<SendNotificationModal
				open={openSendNotificationModal}
				userId={[artisanDetails._id || ""]}
				handleClose={handleCloseNotificationModal}
				artisan={true}
			/>
			<ChangeWithdrawalBankAccount
				open={openChangeBankModal}
				artisan={true}
				handleClose={toggleChangeBankModal}
				withdrawalBank={artisanDetails?.withdrawal_bank}
				userId={String(artisanDetails._id)}
			/>
			<ConfirmationModal
				approve={deleteProfilePicture}
				handleClose={() =>
					setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen)
				}
				loading={isDeletingPicture}
				message='Are you sure you want to delete this profile picture?'
				open={isDeleteConfirmModalOpen}
			/>
			<Flex gap='2rem' wrap='wrap'>
				<div className='profile-info--lhs'>
					<Flex direction='column' align='center' gap='1.5rem'>
						<div className='relative'>
							{artisanDetails?.display_picture && (
								<span
									onClick={() => setIsDeleteConfirmModalOpen(true)}
									className='absolute right-0 cursor-pointer h-10 w-10 bg-red-50 rounded-full flex items-center justify-center'
								>
									<DeleteOutline color='error' />
								</span>
							)}
							<img
								src={artisanDetails.display_picture || userIcon}
								alt='dp'
								className='dp border-[1px]'
							/>
						</div>
						<Flex gap='1.5rem' className={`${isOccupManager ? "!hidden" : ""}`}>
							<a href='tel:+2348110658901'>
								<img src={phone} alt='phone' />
							</a>
							<button
								onClick={() =>
									handleOpenMailModal(
										artisanDetails.email,
										artisanDetails.first_name
									)
								}
							>
								<img style={{ cursor: "pointer" }} src={mail} alt='mail' />
							</button>
							<button onClick={handleOpenNotificationModal}>
								<img style={{ cursor: "pointer" }} src={chat} alt='chat' />
							</button>
						</Flex>
						<Link
							to={`/verification/vendor/${artisanDetails._id}/face-recognition`}
							className=' bg-primary py-3 px-5 text-white rounded-lg'
						>
							View Verification
						</Link>
					</Flex>
				</div>
				<div
					style={{
						width: "70%",
					}}
					className='profile-info--rhs'
				>
					<Flex direction='column' gap='1.0rem'>
						<h3 className='text-xl font-semibold'>
							{artisanDetails.first_name} {artisanDetails.last_name}
						</h3>
						<div className='details'>
							<table
							// style={{
							// 	width: '100%',
							// 	borderSpacing: 8,
							// }}
							>
								<tbody>
									<tr>
										<td className='text key'>Occupation</td>
										<td className='text value'>{artisanDetails.occupation}</td>
									</tr>
									<tr>
										<td className='text key'>Email</td>
										<td className='text value'>{artisanDetails.email}</td>
									</tr>
									<tr>
										<td className='text key'>Phone number</td>
										<td className='text value'>{artisanDetails.phone}</td>
									</tr>
									<tr>
										<td className='text key'>Address</td>
										<td className='text value'>
											{artisanDetails.address.house_address},{" "}
											{artisanDetails.address.city},{" "}
											{artisanDetails.address.state} State
										</td>
									</tr>
									<tr>
										<td className='text key'>City</td>
										<td className='text value'>
											{artisanDetails.address.city}
										</td>
									</tr>
									<tr>
										<td className='text key'>State</td>
										<td className='text value'>
											{artisanDetails.address.state}
										</td>
									</tr>
									<tr>
										<td className='text key'>Rating</td>
										<td className='text value flex items-center gap-1'>
											<p>{artisanDetails.rating}</p>
											<img src='/svgs/star.svg' alt='' className='w-4 h-4' />
										</td>
									</tr>
									<tr>
										<td className='text key'>Profile Stage</td>
										<td className='text value'>
											{artisanDetails.profile_stage}
										</td>
									</tr>
									<tr>
										<td className='text key'>Referral Code</td>
										<td className='text value'>
											{artisanDetails?.referralCode}
										</td>
									</tr>
									<tr>
										<td className='text key'>Status</td>
										<td className='text value'>{artisanDetails.status}</td>
									</tr>
									<tr>
										<td className='text key'>Tier</td>
										<td className='text value'>
											{tierHandler(artisanDetails?.tier)}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Deleted</td>
										<td className='text value'>
											{artisanDetails?.deleted ? "Yes" : "No"}
										</td>
									</tr>
									<tr>
										<td className='text key'>Can Edit Profile</td>
										<td className='text value'>
											{artisanDetails?.can_edit_profile ? "Yes" : "No"}
										</td>
									</tr>
									<tr>
										<td className='text key'>Can Withdraw</td>
										<td className='text value'>
											{artisanDetails?.can_withdraw ? "Yes" : "No"}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account State</td>
										<td className='text value'>
											<div
												style={{
													background: artisanDetails.suspended
														? "rgba(235, 86, 86, 0.2)"
														: "rgba(85, 196, 241, 0.2)",
													color: artisanDetails.suspended
														? "#EB5656"
														: "#55C4F1",
												}}
												className='status-action'
											>
												{artisanDetails.suspended ? "Suspended" : "Active"}
											</div>
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Creation Date</td>
										<td className='text value'>
											{artisanDetails?.createdAt &&
												humanReadableDate(new Date(artisanDetails?.createdAt))}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Last Update Date</td>
										<td className='text value'>
											{artisanDetails?.updatedAt &&
												humanReadableDate(new Date(artisanDetails?.updatedAt))}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='flex flex-row justify-between'>
							<h3 className='text-xl font-semibold'>Withdrawal Wallet</h3>
							<button
								className={`flex items-center gap-4 ${
									isOccupManager ? "!hidden" : ""
								}`}
								onClick={toggleChangeBankModal}
							>
								<svg
									stroke='currentColor'
									fill='currentColor'
									strokeWidth='0'
									viewBox='0 0 24 24'
									height='15'
									width='15'
									xmlns='http://www.w3.org/2000/svg'
								>
									<g id='Edit'>
										<g>
											<path d='M3.548,20.938h16.9a.5.5,0,0,0,0-1H3.548a.5.5,0,0,0,0,1Z'></path>
											<path d='M9.71,17.18a2.587,2.587,0,0,0,1.12-.65l9.54-9.54a1.75,1.75,0,0,0,0-2.47l-.94-.93a1.788,1.788,0,0,0-2.47,0L7.42,13.12a2.473,2.473,0,0,0-.64,1.12L6.04,17a.737.737,0,0,0,.19.72.767.767,0,0,0,.53.22Zm.41-1.36a1.468,1.468,0,0,1-.67.39l-.97.26-1-1,.26-.97a1.521,1.521,0,0,1,.39-.67l.38-.37,1.99,1.99Zm1.09-1.08L9.22,12.75l6.73-6.73,1.99,1.99Zm8.45-8.45L18.65,7.3,16.66,5.31l1.01-1.02a.748.748,0,0,1,1.06,0l.93.94A.754.754,0,0,1,19.66,6.29Z'></path>
										</g>
									</g>
								</svg>
								<span className='text key'>Change Withdrawal Account</span>
							</button>
						</div>
						<div className='details'>
							<table>
								<tbody>
									<tr>
										<td className='text key'>Account Name</td>
										<td className='text value'>
											{artisanDetails?.withdrawal_bank?.account_name || ""}
										</td>
									</tr>
									<tr>
										<td className='text key'>Account Number</td>
										<td className='text value'>
											{artisanDetails?.withdrawal_bank?.account_number || ""}
										</td>
									</tr>
									<tr>
										<td className='text key'>Bank Name</td>
										<td className='text value'>
											{artisanDetails?.withdrawal_bank?.bank_name || ""}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Flex>
				</div>
			</Flex>
		</ProfileInfoContainer>
	);
};

export default ArtisansProfileCard;
