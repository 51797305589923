import { usePagination } from "src/hooks/usePagination";
import { FC, useState } from "react";
import { Flex, Table } from "src/components/ui";
import filterIcon from "src/assets/images/common/filter.svg";
import { FilterButton } from "src/styles/commonStyle";
import styled from "styled-components";
import { WALLETData } from "src/constants/WALLETDATA";
import { formatDateDmy, numberWithCommas } from "src/utils/helpers";
import { DateRangeFilter } from "src/components/common";
import { addDays } from "date-fns";
import { theme } from "src/styles/Theme";

const UserTableContainer = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	margin: 3rem 0;
	border-radius: 16px;
	padding-bottom: 20px;
	.heading {
		border-bottom: 1px solid ${(props) => props.theme.colors.gray_03};
		padding: 20px;

		.title {
			font-weight: 700;
			font-size: 18px;
			line-height: 28px;
			color: ${(props) => props.theme.colors.text_01};
		}
	}
	.table-empty-status {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 60px;
		font-weight: 700;
		font-size: 18px;
		font-style: italic;
	}
`;

const ReferredUserTable: FC<{ rows: ReferredUserProps[] }> = ({ rows }) => {
	const UserTableHeaders = [
		{
			title: "",
			render: (row: ReferredUserProps) => {
				return (
					<div className='flex items-center gap-2'>
						<img
							className='w-10 h-10 rounded-full'
							src={row.dispay_picture}
							alt='dp'
						/>
						<p>
							{row.first_name} {row.last_name}
						</p>
					</div>
				);
			},
		},
	];

	//Date filter
	const [openDateFilter, setOpenDateFilter] = useState(false);
	const handleOpenDateFilter = () => setOpenDateFilter(true);
	const handleCloseDateFilter = () => setOpenDateFilter(false);

	const [state, setState] = useState([
		{
			startDate: new Date("2020-01-01"),
			endDate: addDays(new Date(), 0),
			key: "selection",
			color: theme.colors.darkPurple,
		},
	]);

	const startDate = state[0]?.startDate;
	const endDate = state[0]?.endDate;

	const dateFilteredData = rows?.filter((a: ReferredUserProps) => {
		const date = new Date(a.created_at);
		return date >= startDate && date <= endDate;
	});

	const { page, limit, Pagination } = usePagination({
		page: 1,
		limit: 10,
		total: dateFilteredData.length,
	});

	const paginatedRows = rows.slice((page - 1) * limit, page * limit);

	return (
		<UserTableContainer>
			<DateRangeFilter
				open={openDateFilter}
				handleClose={handleCloseDateFilter}
				state={state}
				setState={setState}
			/>
			<div className='heading'>
				<Flex justify='space-between' align='center'>
					<p className='title'>Referred users ({rows.length})</p>
					{/* <FilterButton onClick={handleOpenDateFilter}>
						<img src={filterIcon} alt='' width={24} height='24px' />
						Filter
					</FilterButton> */}
				</Flex>
			</div>
			{rows.length > 0 ? (
				<Table
					rows={paginatedRows}
					headers={UserTableHeaders}
					showHead
					allowRowClick={false}
				/>
			) : (
				<h5 className='table-empty-status'> No referral for this user yet</h5>
			)}
			<Pagination />
		</UserTableContainer>
	);
};

export default ReferredUserTable;
